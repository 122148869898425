"use client";
import React, { Fragment } from "react";
import Link from "next/link";
import { navLinks } from "../../lib/menu-items";
import { LogoSvg } from "../ui/LogoSvg";
import { signIn, useSession } from "next-auth/react";
import { GlobalAlert } from "./GlobalAlert";

const Navigation = () => {
  const { data: session, status } = useSession();
  return (
    <Fragment>
      <header className="text-gray-600 body-font border-b-2">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <Link href={`/`}>
            <LogoSvg title="Logo" />
          </Link>
          <nav
            className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400 flex flex-wrap items-center text-base justify-center">
            {session ? navLinks.map((link, id) => {
              return <Link
                key={id}
                href={link?.href}
                target={link?._target}
                className="mr-8 hover:text-gray-900 py-6 px-2">
                {link.name}
              </Link>;
            }) : (
              <a
                href={`/api/auth/signin`}
                className="btn text-center"
                onClick={(e) => {
                  e.preventDefault();
                  signIn();
                }}
              >
                Sign in
              </a>
            )}
          </nav>
        </div>
      </header>
      <GlobalAlert />
    </Fragment>
  );
};

export default Navigation;

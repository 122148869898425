import React from "react";
import { useGlobalAlert } from "../../hooks";
import { Alert } from "./Alert";
import { useSession } from "next-auth/react";

export const GlobalAlert = () => {
  const { data: session } = useSession();
  const { data: global_alert, isLoading, isFetching } = useGlobalAlert();
  if (session) {
    return <Alert {...global_alert} />;
  }
  return <></>;
};

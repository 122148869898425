import React from "react";
import { usePathname } from "next/navigation";

export const Alert = ({ plugins_note = null, domains_note = null }) => {
  const pathname = usePathname();

  if (pathname.includes("/domains/")) {
    return <div
      className="container mx-auto bg-yellow-100 border-t border-b border-yellow-500 text-yellow-700 px-4 py-3 dark:bg-gray-800 dark:text-yellow-300"
      role="alert">
      {domains_note ? (
        <>
          <p className="font-bold">Domains Alert</p>
          <p className="text-sm">
            <div dangerouslySetInnerHTML={{ __html: domains_note.replace(new RegExp('\r?\n','g'), '<br />') }} />
          </p>
        </>
      ) : null}
    </div>;
  } else if (pathname.includes("plugins")) {
    return <div
      className="container mx-auto bg-yellow-100 border-t border-b border-yellow-500 text-yellow-700 px-4 py-3 dark:bg-gray-800 dark:text-yellow-300"
      role="alert">
      {plugins_note ? (
        <>
          <p className="font-bold">Plugins Alert</p>
          <p className="text-sm">
            <div dangerouslySetInnerHTML={{ __html: plugins_note.replace(new RegExp('\r?\n','g'), '<br />') }} />
          </p>
        </>
      ) : null}
    </div>;
  }
};

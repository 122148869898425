import { UPTIME_KUMA_API } from "./constants";

export const navLinks = [
  {
    name: "Home",
    description: "",
    href: "/",
    requiresAuth: false,
  },
  {
    name: "Domains",
    description: "",
    href: "/domains",
    requiresAuth: true,
  },
  {
    name: "SSL",
    description: "",
    href: "/ssl",
    requiresAuth: true,
  },
  {
    name: "Plugins",
    description: "",
    href: "/plugins",
    requiresAuth: true,
  },
  {
    name: "Servers",
    description: "",
    href: "/servers",
    requiresAuth: true,
  },
  {
    name: "Portainer",
    description: "",
    href: "https://80.229.32.15:9443/",
    _target: "blank",
    requiresAuth: true,
  },
  {
    name: "Kuma",
    description: "",
    href: UPTIME_KUMA_API,
    _target: "blank",
    requiresAuth: true,
  },
  {
    name: "Sign Out",
    signOut: true,
    description: "",
    href: "/api/auth/signout",
    requiresAuth: true,
  },
];
